import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function Template({ data }) {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO 
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
        image={post.frontmatter.image}
        article={ true }
      />
      
      <div className="blogpage-container">
        <div className="blogpage-item">
          
          <h1 className="blogpost-title">{post.frontmatter.title}</h1>
          <small><em>published on</em> {post.frontmatter.date}</small>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr className="blogpage-hr" />
          <Link className="blogpost-goback btn" to="/blog">Go Back</Link>
        </div>
      </div>
    </Layout>
  )
}


export const postQuery = graphql `
query BlogPostByPath($path: String!) {
  markdownRemark(
    frontmatter: {
      path: {
        eq: $path
      }
    }
  ) {
    html
    frontmatter {
      title
      date(formatString: "MMMM DD, YYYY")
      path
      tags
      excerpt
      image
    }
  }
}
`